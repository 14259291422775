import React from 'react';
import bemCn from 'bem-cn';
import { IMG_RESIZES } from '../../consts/resizer.consts';
import { ImageHelper } from '../../classes/image-helper.class';
import defSpotImg from '../../assets/img/blog/blog-placeholder.png?sizes[]=450,sizes[]=600';
import PictureSet from '../picture-set';
import './style.scss';

const b = bemCn('blog-article-simple');

const BlogArticleSimple = ({ data, mobileSquare, useStateSSR }) => {
    const thumbnail =
        data.thumbnail || data.img
            ? ImageHelper.getUrlFromOptions(data.thumbnail || data.img, `fit-in/${IMG_RESIZES.x0128}`)
            : defSpotImg;

    const handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        window.location.href = data.slug || data.url;
    };

    return (
        <div className={b()} onClick={handleClick}>
            <PictureSet
                imgs={thumbnail}
                className={b('thumbnail').mix(mobileSquare ? 'mobile-square' : '')}
                width={96}
                height={72}
                useState={useStateSSR}
                SSR={!!useStateSSR}
                alt={data.title + ' thumbnail'}
            />
            <a href={data.slug || data.url} className={b('title')}>
                {data.title}
            </a>
        </div>
    );
};

export default BlogArticleSimple;
