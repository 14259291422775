import React from 'react';
import BemCn from 'bem-cn';
import {
    FacebookIcon,
    FacebookMessengerIcon,
    LineIcon,
    LinkedinIcon,
    PinterestIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
    WhatsappIcon,
    XIcon,
} from 'react-share';
import { FB_APP_ID } from '../../consts/env.consts';
import { UTM_MEDIUM } from '../../consts/spot.consts';
import useMobileScreen from '../../services/hooks/useMobileScreen';
import { copyTextToClipboard } from '../../helpers/clipboard';
import ModalMobile from '../modal-mobile';
import SniffButton from '../sniff-button';
import SniffInput from '../sniff-input';
import './index.scss';

const b = BemCn('spot-share-button');

const RenderCopy = ({ isMobile, copyLink }) => {
    const pageUrl = window.location.href;

    return (
        <>
            <p className="snif-p snif-medium mb-1 mt-2 mt-md-0">Copy link URL</p>
            <div className="share-box-copy mb-0 mb-md-4">
                <SniffInput type="text" value={pageUrl} readOnly />
                <SniffButton className="share-box-copy__action" size="lg" block="mobile" onClick={copyLink}>
                    {isMobile ? 'Copy link' : 'Copy'}
                </SniffButton>
            </div>
        </>
    );
};

const SharePage = ({ TriggerComp, shareModal, toggleShareModal, title = 'Share this spot!', shareBlog }) => {
    const isMobile = useMobileScreen();
    const addedUtm = '?utm_source=blogshare&utm_medium=share_button&utm_campaign={blog_share}';
    const baseUrl = typeof window != 'undefined' ? window.location.href : '';
    const pageUrl = encodeURI(`${baseUrl}${shareBlog ? addedUtm : ''}`);

    const copyLink = async () => {
        try {
            await copyTextToClipboard(pageUrl);
        } catch (error) {
            console.warn(error);
        }
    };

    const mobileShare = async () => {
        try {
            const shareData = { url: pageUrl };
            await navigator.share(shareData);
        } catch (err) {
            console.warn(err);
            if (err?.name === 'NotAllowedError') {
                toggleShareModal();
            }
        }
    };

    const desktopShare = async (medium) => {
        try {
            const shareSpotLink = encodeURIComponent(`${baseUrl}${shareBlog ? addedUtm : ''}`);
            let shareLink = '';
            if (medium === UTM_MEDIUM.FACEBOOK.type) {
                shareLink = UTM_MEDIUM.FACEBOOK.link + shareSpotLink;
            } else if (medium === UTM_MEDIUM.TWITTER.type) {
                shareLink = UTM_MEDIUM.TWITTER.link + shareSpotLink;
            } else if (medium === UTM_MEDIUM.PINTEREST.type) {
                shareLink = UTM_MEDIUM.PINTEREST.link + shareSpotLink;
            } else if (medium === UTM_MEDIUM.LINKEDIN.type) {
                shareLink = UTM_MEDIUM.LINKEDIN.link + shareSpotLink;
            } else if (medium === UTM_MEDIUM.TUMBLR.type) {
                shareLink = UTM_MEDIUM.TUMBLR.link + shareSpotLink;
            } else if (medium === UTM_MEDIUM.REDDIT.type) {
                shareLink = UTM_MEDIUM.REDDIT.link + shareSpotLink;
            } else if (medium === UTM_MEDIUM.WHATSAPP.type) {
                shareLink = UTM_MEDIUM.WHATSAPP.link + shareSpotLink;
            } else if (medium === UTM_MEDIUM.MESSENGER.type) {
                shareLink = UTM_MEDIUM.MESSENGER.link + shareSpotLink + `&app_id=${FB_APP_ID}` + `&redirect_uri=${shareSpotLink}`;
            } else if (medium === UTM_MEDIUM.TELEGRAM.type) {
                shareLink = UTM_MEDIUM.TELEGRAM.link + shareSpotLink;
            } else if (medium === UTM_MEDIUM.LINE.type) {
                shareLink = UTM_MEDIUM.LINE.link + shareSpotLink;
            }
            const width = 800;
            const height = 720;
            const left = (screen.width - width) / 2;
            const top = (screen.height - height) / 3;
            window.open(shareLink, '_blank', `location=yes,width=${width},height=${height},left=${left},top=${top},scrollbars=yes,status=yes`);
        } catch (error) {
            console.warn(error);
        }
    };

    return (
        <div>
            <div onClick={isMobile ? mobileShare : toggleShareModal}>
                <TriggerComp />
            </div>
            <div className={b('box')}>
                <ModalMobile isOpen={shareModal} toggle={toggleShareModal} title={title} className="share-modal">
                    <p className="snif-p snif-medium mb-2">Social Media</p>
                    <div className="share-box mb-2">
                        <div className="react-share__ShareButton" onClick={() => desktopShare(UTM_MEDIUM.FACEBOOK.type)}>
                            <FacebookIcon size={40} round />
                        </div>
                        <div className="react-share__ShareButton" onClick={() => desktopShare(UTM_MEDIUM.TWITTER.type)}>
                            <XIcon size={40} round />
                        </div>
                        <div className="react-share__ShareButton" onClick={() => desktopShare(UTM_MEDIUM.PINTEREST.type)}>
                            <PinterestIcon size={40} round />
                        </div>
                        <div className="react-share__ShareButton" onClick={() => desktopShare(UTM_MEDIUM.LINKEDIN.type)}>
                            <LinkedinIcon size={40} round />
                        </div>
                        <div className="react-share__ShareButton" onClick={() => desktopShare(UTM_MEDIUM.TUMBLR.type)}>
                            <TumblrIcon size={40} round />
                        </div>
                        <div className="react-share__ShareButton" onClick={() => desktopShare(UTM_MEDIUM.REDDIT.type)}>
                            <RedditIcon size={40} round />
                        </div>
                    </div>
                    <div className="d-none d-md-block">
                        <RenderCopy {...{ isMobile, copyLink }} />
                    </div>
                    <p className="snif-p snif-medium mb-2">Messaging</p>
                    <div className="share-box">
                        <div className="react-share__ShareButton" onClick={() => desktopShare(UTM_MEDIUM.WHATSAPP.type)}>
                            <WhatsappIcon size={40} round />
                        </div>
                        <div className="react-share__ShareButton" onClick={() => desktopShare(UTM_MEDIUM.MESSENGER.type)}>
                            <FacebookMessengerIcon size={40} round />
                        </div>
                        <div className="react-share__ShareButton" onClick={() => desktopShare(UTM_MEDIUM.TELEGRAM.type)}>
                            <TelegramIcon size={40} round />
                        </div>
                        <div className="react-share__ShareButton" onClick={() => desktopShare(UTM_MEDIUM.LINE.type)}>
                            <LineIcon size={40} round />
                        </div>
                    </div>
                    <div className="d-block d-md-none">
                        <RenderCopy {...{ isMobile, copyLink }} />
                    </div>
                </ModalMobile>
            </div>
        </div>
    );
};

export default SharePage;
