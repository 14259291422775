import React, { useEffect, useState } from 'react';
import bemCn from 'bem-cn';
import { states } from '../../consts/states';
import { sniffDesc } from '../../consts/seo.consts';
import { TOP_CITIES } from '../../consts/top-cities';
import useMobileScreen from '../../services/hooks/useMobileScreen';
import { RouteFormatter } from '../../routes';
import { cityLink, stateLink, toColumns } from '../../helpers/column-view';
import { escapeString } from '../../helpers/escape-string';
import SniffButton from '../sniff-button';
import SharePage from '..//spot-share-button/share-page';
import SnifHr from '../snif-hr';
import SvgShare from 'src/assets/svg/SvgShare.svg';
import './style.scss';

const b = bemCn('blog-footer');

const TriggerComp = () => (
    <div className={b('share-mobile')}>
        <SniffButton color="secondary" size="lg">
            <SvgShare className="me-1" />
            Share
        </SniffButton>
    </div>
);

const BlogFooter = ({ useStateSSR }) => {
    const isMobile = useMobileScreen();
    const [column, setColumn] = useState(2);
    const [shareModal, setShareModal] = useState(false);

    const toggleShareModal = () => setShareModal((s) => !s);

    useEffect(() => {
        if (!isMobile) {
            setColumn(5);
        }
    }, []);

    return (
        <div className={b()}>
            <div className={b('border')} />
            <div className="mx-2 mx-md-0">
                <div className="desktop-container">
                    <h2 className="snif-m2 snif-l1-pc snif-semibold mb-1 mb-md-3">About Sniffspot</h2>
                    <p className="snif-s2 snif-p-pc mb-4 mb-md-5 pb-md-1">{sniffDesc}</p>
                    <h2 className="snif-m2 snif-medium mb-2">Browse by top cities</h2>
                    <div id="footer-top-cities" className={b('top-list-box')}>
                        {toColumns(TOP_CITIES, column).map((col, i) => (
                            <div key={i} className="snif-p text-dark">
                                {col.map((c, j) => cityLink(c.city || c.name, c.url, j, !!useStateSSR))}
                            </div>
                        ))}
                    </div>
                    <div className="mt-4 mb-5">
                        <SnifHr w100 />
                    </div>
                    <h2 className="snif-m2 snif-medium mb-2">Browse by top states</h2>
                    <div id="footer-top-states" className={b('top-list-box')}>
                        {toColumns(states, column).map((col, i) => (
                            <div key={i} className="snif-p text-dark">
                                {col.map((c, j) => stateLink(c, escapeString(c), j, !!useStateSSR))}
                            </div>
                        ))}
                    </div>
                </div>
                <div className={b('action')}>
                    <div className="desktop-container">
                        <h2 className="snif-m1 snif-l2-pc snif-semibold text-center mb-4">Rent safe and private dog parks hosted by locals</h2>
                        <a href={RouteFormatter.listings({})} className="d-md-block text-center">
                            <SniffButton size="lg" block="mobile">
                                Explore spots near me
                            </SniffButton>
                        </a>
                    </div>
                </div>
            </div>
            <SharePage shareBlog {...{ title: 'Share this page!', TriggerComp, shareModal, toggleShareModal }} />
        </div>
    );
};

export default BlogFooter;
