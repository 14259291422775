import React from 'react';
import bemCn from 'bem-cn';
import { IMG_RESIZES } from '../../consts/resizer.consts';
import { ImageHelper } from '../../classes/image-helper.class';
import defSpotImg from '../../assets/img/blog/blog-placeholder.png?sizes[]=450,sizes[]=600';
import PictureSet from '../picture-set';
import './style.scss';

const b = bemCn('blog-article-desktop-low');

const BlogArticleDesktopLow = ({ data, useStateSSR }) => {
    const thumbnail = data.thumbnail
        ? ImageHelper.getUrlFromOptions(data.thumbnail, `fit-in/${IMG_RESIZES.x0256}`)
        : defSpotImg;
    const desc = data.descriptionShort || data.description;

    const onClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        window.location = data.slug;
    };

    return (
        <div className={b()} onClick={onClick}>
            <PictureSet
                imgs={thumbnail}
                className={b('thumbnail')}
                width={368}
                height={264}
                useState={useStateSSR}
                SSR={!!useStateSSR}
                alt={data.title + ' thumbnail'}
            />
            <h4 className={b('title')}>
                <a href={data.slug}>{data.title}</a>
            </h4>
            <div className={b('desc')} dangerouslySetInnerHTML={{ __html: desc }} />
        </div>
    );
};

export default BlogArticleDesktopLow;
