/* eslint-disable react/no-unknown-property */
import React from 'react';
import bemCn from 'bem-cn';
import { RouteFormatter } from '../../routes';
import { escapeString } from '../../helpers/escape-string';
import './index.scss';

const b = bemCn('spot-breadcrumbs');
const homeLink = RouteFormatter.home();

const RenderLink = ({ d, href, lastIdx }) =>
    lastIdx ? (
        <span property="name" className={b('name').mix('text-light bc-spot-title')}>
            {d.name}
        </span>
    ) : (
        <a property="item" typeof="WebPage" id={href} href={href}>
            <span property="name" className={b('name').mix('link')}>
                {d.name}
            </span>
        </a>
    );

const SpotBreadcrumbs = ({ listBread = [], noPaddingTop, general }) => {
    if (!listBread.length) {
        return '';
    }

    return (
        <div className={b.mix(noPaddingTop ? 'pt-0' : '')}>
            <ol vocab="https://schema.org/" typeof="BreadcrumbList">
                <li property="itemListElement" typeof="ListItem">
                    <a property="item" typeof="WebPage" id={homeLink} href={homeLink}>
                        <span property="name" className={b('name').mix('link')}>
                            Home
                        </span>
                    </a>
                    <meta property="position" content="1" />
                </li>
                {listBread.map((d, i) => {
                    const lastIdx = i + 1 === listBread.length;
                    let href = d.url || RouteFormatter.listings({ first: d.url || escapeString(d.name) });

                    if (i === 2 && !lastIdx) {
                        if (general) {
                            href = d.url;
                        } else {
                            href = RouteFormatter.listings({
                                first: d.url || `${escapeString(d.name)}-${escapeString(d.short)}`,
                            });
                        }
                    }

                    return (
                        <React.Fragment key={i}>
                            <div className={b('icon-right')}>
                                <svg
                                    width="12"
                                    height="20"
                                    viewBox="0 0 12 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M2.0005 19.9985C1.4885 19.9985 0.9765 19.8025 0.5865 19.4125C-0.1955 18.6305 -0.1955 17.3665 0.5865 16.5845L7.1965 9.97454L0.8365 3.38854C0.0705 2.59254 0.0924992 1.32654 0.886499 0.560545C1.6825 -0.205455 2.9485 -0.183456 3.7145 0.608544L11.4385 8.60854C12.1965 9.39454 12.1865 10.6405 11.4145 11.4125L3.4145 19.4125C3.0245 19.8025 2.5125 19.9985 2.0005 19.9985Z"
                                        fill="currentColor"
                                    />
                                </svg>
                            </div>
                            <li
                                property="itemListElement"
                                typeof="ListItem"
                                className={lastIdx ? 'bc-spot-title-wrapper' : ''}
                            >
                                <RenderLink {...{ d, href, lastIdx }} />
                                <meta property="position" content={i + 2} />
                            </li>
                        </React.Fragment>
                    );
                })}
            </ol>
        </div>
    );
};

export default SpotBreadcrumbs;
